<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1>Export Leads</h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="leads()"
        >
          All Leads
        </el-button>
      </div>
    </el-col>
  </el-row>
  <div class="panel panel-default">
    <div style="padding-left:10px; padding-bottom:10px;">
      <el-row
        :gutter="20"
        style="border-bottom: 1px solid #00000042;padding-bottom:15px;"
      >
        Drag & Drop For Change Sequence of Columns
      </el-row>
      <el-row :gutter="20">
        <el-button
          id="firstname"
          plain
        >
          First Name
        </el-button>
        <el-button
          id="lastname"
          plain
        >
          Last Name
        </el-button>
        <el-button
          id="email"
          plain
        >
          Email
        </el-button>
        <el-button
          id="telephone"
          plain
        >
          Phone Number
        </el-button>        
        <el-button
          id="officephone"
          plain
        >
          Office Phone Number
        </el-button>
        <el-button
          id="company"
          plain
        >
          Company
        </el-button>
        <el-button
          id="address_1"
          plain
        >
          Address
        </el-button>
        <el-button
          id="country"
          plain
        >
          Country
        </el-button>
        <el-button
          id="zone"
          plain
        >
          State <i class="el-icon-close" />
        </el-button>
      </el-row>
      <el-row :gutter="20">        
        <el-button
          id="city"
          plain
        >
          City
        </el-button>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Download Leads"
            placement="top-start"
          >     
            <el-button
              id="customer_source"
              plain
              icon="el-icon-download"
              type="danger"
              @click="downloadleads();"
            >
              Download Leads
            </el-button>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
 <script>
import AuthService from "@/api/auth.service";
import LeadsService from "@/api/leads.service";
import authHeader from '@/api/auth-header';
  export default {
    methods: {        
     leads(){
		this.$router.push("/leads");
	},
    downloadleads(){
         return LeadsService.exportcsvLeads().then(response => {
              console.log("before: " + response.data.data);
                return response;
              });
        }
    }
  }
</script>
<style  lang="scss">
     .panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}

    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
     div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>